import { postAPICall } from "../helpers/httpHelper";
import { db } from "../utils/db";

export const fetchEntityNotificationTemplates = async (params) => {
  let templates = await fetchEntityNotificationTemplatesFromLocal(params);
  if (!templates) {
    templates = await fetchEntityNotificationTemplatesFromDatabase(params);
  }
  return templates;
};

export const fetchEntityNotificationTemplatesFromLocal = async (params) => {
  return await db.notification_templates
    .filter((temp) => temp?.entity_id == params.entity_id || temp?.entity_id?._id == params.entity_id)
    .toArray();
};

export const fetchEntityNotificationTemplatesFromDatabase = async (params) => {
  let response = await postAPICall("GET", "email-template/list", {
    page: 1,
    limit: 100,
    entity_id: params.entity_id,
    getAll: true,
  });
  return response?.data;
};
